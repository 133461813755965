import React from "react";
import { useQuery } from "../../Hooks/useQuery";
import s from "./Footer.module.scss";

const Footer = () => {
    const catalogs = useQuery({apiKey: 'category'})
    const allPoint = useQuery({apiKey: 'coords'})
  return (
    <footer>
      <div className={s.footer_block}>
        <div className={s.column}>
            <div className={s.title}>Каталог изделий</div>
            <ul>
                {catalogs?.map((el, index) => 
                    <li key={index}><a href={`/catalog/?${index}`}>{el.name}</a></li>
                )}
            </ul>
        </div>

        <div className={s.column}>
            <div className={s.title}>Телефоны:</div>
            <ul>
                <li><a href="tel:+79066511111">+7 (906) 651 1111</a></li>
                <li><a href="tel:+74822345590">+7 (4822) 34-55-90</a></li>
            </ul>
            <div className={s.title}>Режим работы:</div>
            <ul>
                <li>Пн-Пт: с 8:00 до 17:00</li>
                <li>Сб: с 9:00 до 14:00</li>
                <li>Воскресенье: Выходной</li>
            </ul>
            <div className={s.title}>Электронный адрес:</div>
            <ul>
                <li><a href="mailto:grano69@yandex.ru">grano69@yandex.ru</a></li>
            </ul>
        </div>
        <div className={s.column}>
            <div className={s.title}>Адреса:</div>
            <ul>
                {allPoint?.map((el, index) => {
                    return <li key={index}>{el.name}<br/><a className={s.adress_tel} href={`tel:${el.tel}`}>{el.tel}</a> <br/>{el.employee}</li>
                })}
            </ul>
        </div>
        
      </div>
      
    </footer>
  );
};
export default Footer;
