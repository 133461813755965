import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import css from "./PortfolioPage.module.scss";
import { useQuery } from "../../../Hooks/useQuery";
import { Link } from "react-router-dom";
import "swiper/css/navigation";
import "./swiperNavigation.scss"
import cn from "classnames";

const ModalGalery = ({ element, setModalOpen, modalOpen }) => {
  return (
    modalOpen && (
      <>
        <div className={css.maskModal} />
        <div className={css.close_btn} onClick={() => setModalOpen(false)} />
        <div className={css.modal}>
          <Swiper
            slidesPerView={1}
            spaceBetween={50}
            loop={true}
            navigation={true}
            modules={[Autoplay, Navigation]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
          >
            {element.map((el) => (
              <SwiperSlide>
                <div className={css.slide}>
                  <img src={el} alt="" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </>
    )
  );
};
const PortfolioPage = () => {
  const portfolios = useQuery({ apiKey: "portfolios" });
  const classesArray = [
    css.firstType,
    css.secondType,
    css.thirdType,
    css.fourthType,
  ];
  const [modalOpen, setModalOpen] = useState(false);
  const [imagesList, setImagesList] = useState([]);
  const modalInit = (list) => {
    setModalOpen(true);
    setImagesList(list);
  };
  return (
    <>
      <div className={css.PortfolioPage}>
        <Link to="/">←</Link>
        <h1>Наши работы:</h1>
        {portfolios?.map(
          (element, index) =>
            element.images.length >= 3 && (
              <div key={index} className={css.portfolio_container}>
                <span className={css.title}>{element.name}</span>
                <span className={css.subtitle}>{element.description}</span>
                <div
                  className={cn(
                    css.content,
                    classesArray[
                      Math.floor(Math.random() * classesArray.length)
                    ]
                  )}
                >
                  {element.images.map((el, idx) => {
                    return (
                      idx < 3 && (
                        <div
                          key={idx}
                          style={{
                            background: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.25) ), url(${el}) center / cover no-repeat `,
                          }}
                          onClick={() => modalInit(element.images)}
                        />
                      )
                    );
                  })}
                </div>
              </div>
            )
        )}
      </div>
      <ModalGalery
        element={imagesList}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
      />
    </>
  );
};
export default PortfolioPage;
