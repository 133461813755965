import React from "react";
import s from "./PortfolioBlock.module.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const PortfolioBlock = (props) => {
  const [data, setData] = useState(false);
  useEffect(() => {
    let xhr = new XMLHttpRequest(); // инициализируем только что созданный запрос
    xhr.open("GET", "portfolioMainPage/data.json"); // определяем параметры для запроса 
    xhr.responseType = "text" // указываем тип данных, ожидаемых в ответе
    xhr.onreadystatechange = function() { // добавляем обработчик события
       // проверяем состояние запроса и числовой код состояния HTTP ответа
       if (xhr.readyState === 4 && xhr.status === 200) {
         const resultText = xhr.responseText; // строковое значение, содержащее ответ на запрос в виде текста
         const json = JSON.parse(resultText) // выводим в консоль это значение
         setData(json.portfolio)
       }
    }; 
    xhr.send(); // отправляем запрос на сервер
  },[]);

  return (
    <div className={s.portfolio_wrapper}>
      <h5>
        Выполненные проекты <Link to="/portfolio/">Показать ещё</Link>
      </h5>
      {data && (
        <div className={s.content}>
          <div
            className={s.vertical}
            style={{
              background: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5) ), url('portfolioMainPage/vertical.jpg') center / cover no-repeat `,
            }}
          >
            <div className={s.description}>
              <p>{data[0].description}</p>
            </div>
            <div className={s.titles}>
              <span>{data[0].category}</span>
              <p>{data[0].name}</p>
            </div>
          </div>
          <div
            className={s.long}
            style={{
              background: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5) ), url('portfolioMainPage/long.jpg') center / cover no-repeat`,
            }}
          >
            <div className={s.description}>
            <p>{data[1].description}</p>
            </div>
            <div className={s.titles}>
              <span>{data[1].category}</span>
              <p>{data[1].name}</p>
            </div>
          </div>
          <div
            className={s.bottom_first}
            style={{
              background: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5) ), url('portfolioMainPage/bottom_first.jpg') center / cover no-repeat`,
            }}
          >
            <div className={s.description}>
            <p>{data[2].description}</p>
            </div>
            <div className={s.titles}>
              <span>{data[2].category}</span>
              <p>{data[2].name}</p>
            </div>
          </div>
          <div
            className={s.bottom_last}
            style={{
              background: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5) ), url('portfolioMainPage/bottom_last.jpg') center / cover no-repeat`,
            }}
          >
            <div className={s.description}>
            <p>{data[3].description}</p>
            </div>
            <div className={s.titles}>
              <span>{data[3].category}</span>
              <p>{data[3].name}</p>
            </div>
          </div>
          <div className={s.title_grano}>
            <h6>Гранитные памятники, надгробия, оптовая продажа в Твери </h6>
            <p>в Твери и Тверской области</p>
          </div>
          <div className={s.description_grano}>
            <p>
              Гранитные надгробия и памятники – основная специализация компании
              «ГРАНО».Мы работаем на российском рынке с 1980 года, поставляя из
              Карелии гранитные глыбы и выпиливаем из них слэбы, которые в
              дальнейшем реалезуются на разную продукцию: стеллы, подставки,
              цветники, столики, ограды ступени, подоконники и т.д. по вашему
              желанию. Мы являемся крупнейшим поставщиком в Тверской области, а
              так же поставляем гранитные памятники оптом во все города России.
              Наши дизайнеры постоянно разрабатывают новые модели, следуя
              новейшим тенденциям дизайна. В результате ассортимент предлагаемой
              продукции постоянно пополняется, и гранитные памятники,
              спроектированные нашими специалистами, пользуются спросом уже
              долгие годы.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
export default PortfolioBlock;
