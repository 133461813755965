import React from "react";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "../../Hooks/useQuery";
import axios from "axios";
import s from "./Form.module.scss";

const Form = ({ optionList, defaulCategory, product, price }) => {
  useEffect(() => {
    let phoneInputs = document.querySelectorAll("input[data-tel-input]");
    let getInputNumbersValue = function (input) {
      return input.value.replace(/\D/g, "");
    };

    let onPhonePaste = function (e) {
      let input = e.target,
        inputNumbersValue = getInputNumbersValue(input);
      let pasted = e.clipboardData || window.clipboardData;

      if (pasted) {
        let pastedText = pasted.getData("Text");
        if (/\D/g.test(pastedText)) {
          input.value = inputNumbersValue;
          return;
        }
      }
    };

    let onPhoneInput = function (e) {
      let input = e.target,
        inputNumbersValue = getInputNumbersValue(input),
        selectionStart = input.selectionStart,
        formattedInputValue = "";

      if (!inputNumbersValue) {
        return (input.value = "");
      }

      if (input.value.length !== selectionStart) {
        if (e.data && /\D/g.test(e.data)) {
          input.value = inputNumbersValue;
        }
        return;
      }

      if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
        if (inputNumbersValue[0] === "9")
          inputNumbersValue = "7" + inputNumbersValue;
        let firstSymbols = inputNumbersValue[0] === "8" ? "+7" : "+7";
        formattedInputValue = input.value = firstSymbols + " ";
        if (inputNumbersValue.length > 1) {
          formattedInputValue += "(" + inputNumbersValue.substring(1, 4);
        }
        if (inputNumbersValue.length >= 5) {
          formattedInputValue += ") " + inputNumbersValue.substring(4, 7);
        }
        if (inputNumbersValue.length >= 8) {
          formattedInputValue += "-" + inputNumbersValue.substring(7, 9);
        }
        if (inputNumbersValue.length >= 10) {
          formattedInputValue += "-" + inputNumbersValue.substring(9, 11);
        }
      } else {
        formattedInputValue = "+" + inputNumbersValue.substring(0, 16);
      }
      input.value = formattedInputValue;
    };
    let onPhoneKeyDown = function (e) {
      let inputValue = e.target.value.replace(/\D/g, "");
      if (e.keyCode === 8 && inputValue.length === 1) {
        e.target.value = "";
      }
    };
    for (let phoneInput of phoneInputs) {
      phoneInput.addEventListener("keydown", onPhoneKeyDown);
      phoneInput.addEventListener("input", onPhoneInput, false);
      phoneInput.addEventListener("paste", onPhonePaste, false);
    }
  }, []);

  const catalogState = useQuery({ apiKey: "category" });

  const formRef = useRef(null);
  const [sendform, setSendForm] = useState(false);
  function handleSubmit(e) {
    e.preventDefault();
    const tokenBot = "bot6015555334:AAHVvgRB-828iNYB4AwDB1dATtiXCvrCq6A";
    const chanelId = "-1001880996765";
    const URI_API = `https://api.telegram.org/${tokenBot}/sendMessage`;
    let message = `<b>Уведомление</b>\n`;
    message += `<b>Имя:</b> ${e.target.name.value}\n`;
    message += `<b>Номер Телефона:</b> ${e.target.phone_number.value}\n`;
    message += `<b>Почта:</b> ${e.target.e_mail.value}\n`;
    message += `<b>Текст:</b> ${e.target.textArea.value}\n`;
    message += `<b>Категория:</b> ${e.target.categories.value}\n`;
    if (product) message += `<b>Товар:</b> ${product}\n`;
    if (optionList) message += `<b>Параметры:</b> ${String(optionList)}\n`;
    if (price)
      message += `<b>Цена пользователя на сайте:</b> ${String(price)} Рублей`;

    message &&
      axios
        .post(URI_API, {
          chat_id: chanelId,
          parse_mode: "html",
          text: message,
        })
        .then((res) => {
          setSendForm(true);
        })
        .catch((err) => {
          console.warn(err);
        });
  }
  return (
    <div className={s.form}>
      <h6 className={sendform && s.notvisible}>
        Оставьте заявку и наш менеджер свяжется с Вами:
      </h6>
      {sendform && (
        <div className={s.thanks}>
          <p>Спасибо!</p>
          <p>Мы свяжемся с вами в ближайшее время!</p>
        </div>
      )}
      <form
        ref={formRef}
        onSubmit={handleSubmit}
        className={sendform && s.notvisible}
        id='telegram-to-form'
      >
        <div>
          <input name="name" type="text" required placeholder="Имя" />
          <input
            name="phone_number"
            type="tel"
            placeholder="+7(____)___-__-__"
            data-tel-input={true}
            maxLength="18"
            required
          />
          <input name="e_mail" type="email" placeholder="Почта" required />
          <select name="categories">
            {catalogState &&
              catalogState.map((elem, index) => (
                <option
                  selected={index === defaulCategory ? "selected" : ""}
                  key={index}
                  value={elem.name}
                >
                  {elem.name}
                </option>
              ))}
          </select>
        </div>
        <div>
          <textarea name="textArea"></textarea>
          <input type="submit" value="Отправить" />
        </div>
      </form>
    </div>
  );
};
export default Form;
