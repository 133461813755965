import React from "react";

function Separator() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 1610 18"
    >
      <path stroke="#474745" d="M0 8.5L750 8.5"></path>
      <path stroke="#474745" d="M860 8.5L1610 8.5"></path>
      <path
        fill="#fff"
        stroke="#474745"
        d="M0 0.792H9.746V10.538H0z"
        transform="matrix(.6106 .79194 -.6106 .79194 786.046 .165)"
      ></path>
      <path
        fill="#fff"
        stroke="#474745"
        d="M0 0.792H9.746V10.538H0z"
        transform="scale(.8635 1.11998) rotate(45 462.311 1116.62)"
      ></path>
      <path
        fill="#fff"
        stroke="#474745"
        d="M0 0.792H9.746V10.538H0z"
        transform="matrix(.6106 .79194 -.6106 .79194 811.718 .165)"
      ></path>
      <path
        fill="#fff"
        stroke="#474745"
        d="M0 0.792H9.746V10.538H0z"
        transform="matrix(.6106 .79194 -.6106 .79194 824.71 .165)"
      ></path>
    </svg>
  );
}

export default Separator;
