import React, { useEffect, useState } from "react";
import { useQuery } from "../../../../Hooks/useQuery";
import s from "./MapBlock.module.scss";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import cn from "classnames";

const MapBlock = () => {
  const allPoint = useQuery({ apiKey: "coords" });

  const [openAdress, setOpenAdress] = useState(false);
  const [activePoint, setActivePoint] = useState(false);
  const [mapPoint, setMapPoint] = useState([56.864713, 35.95303]);

  const [mapState, setMapState] = useState({
    center: mapPoint,
    zoom: 15,
  });

  const mainAdress = {
    name: "г. Тверь, пос.Большие перемерки д. 1",
    tel: "+7 (904) 016-11-38",
    employee: "Дмитрий",
    yacoord: ["56.822814", "35.951674"],
  };

  useEffect(() => {
    setMapState({
      center: mapPoint,
      zoom: 12,
    });
  }, [mapPoint, allPoint]);

  return (
    <div className={s.map_block}>
      <div className={s.contacts}>
        <h6>Офисы по адресу:</h6>
        <ul>
          <li
            onClick={() => {
              setMapPoint(
                mainAdress.yacoord.map((string) => parseFloat(string))
              );
              setActivePoint(mainAdress);
            }}
          >
            170017, г. Тверь, пос.Большие перемерки д. 1;
          </li>
          <li>
            <a href="tel:+79066511111" title="Позвонить">
              +7 906 651 1111
            </a>
          </li>
          <li>
            <a href="mailto:grano69@yandex.ru" title="Написать">
              E-mail: grano69@yandex.ru
            </a>
          </li>
        </ul>
        <button
          className={s.adressbtn}
          title="Посмотреть карту"
          onClick={() => setOpenAdress(!openAdress)}
        >
          Посмотреть другие адреса
        </button>
        <ul className={cn(s.adresses, openAdress && s.adresses_active)}>
          {allPoint?.map((adress, index) => (
            <li
              key={index}
              onClick={() => {
                setMapPoint(adress.yacoord.map((string) => parseFloat(string)));
                setActivePoint(adress);
              }}
            >
              {adress.name}
            </li>
          ))}
        </ul>
      </div>
      <div className={s.map}>
        <YMaps>
          <Map state={mapState}>
            <Placemark
              geometry={mainAdress.yacoord.map((string) => parseFloat(string))}
              onClick={() => setActivePoint(mainAdress)}
            />
            {allPoint?.map((point, index) => (
              <Placemark
                key={index}
                geometry={point.yacoord.map((string) => parseFloat(string))}
                onClick={() => setActivePoint(point)}
              />
            ))}
          </Map>
        </YMaps>
        <>
          {mainAdress.name === activePoint.name && (
            <div className={cn(s.baloon)}>
              <div className={s.adress}>
                Адрес: <adress>{mainAdress.name}</adress>
              </div>
              <div className={s.tel}>
                Телефон:
                <a href={`tel:${mainAdress.tel}`}> {mainAdress.tel}</a>
              </div>
              <div className={s.employee}>
                ФИО сотрудника: <p>{mainAdress.employee}</p>
              </div>
              <div className={s.closebtn} onClick={() => setActivePoint(false)}>
                Закрыть
              </div>
            </div>
          )}
          {allPoint &&
            allPoint.map(
              (point, index) =>
                activePoint === point && (
                  <div key={index} className={cn(s.baloon)}>
                    <div className={s.adress}>
                      Адрес: <adress>{point.name}</adress>
                    </div>
                    <div className={s.tel}>
                      Телефон: <a href={`tel:${point.tel}`}> {point.tel}</a>
                    </div>
                    <div className={s.employee}>
                      ФИО сотрудника: <p>{point.employee}</p>
                    </div>
                    <div
                      className={s.closebtn}
                      onClick={() => setActivePoint(false)}
                    >
                      Закрыть
                    </div>
                  </div>
                )
            )}
        </>
      </div>
    </div>
  );
};
export default MapBlock;
