import React from "react";
import s from "./ReasonsBlock.module.scss";
import natural from "./../../../../static/reasons/natural.svg"
import factory from "./../../../../static/reasons/factory.svg"
import diamant from "./../../../../static/reasons/diamant.svg"
import key from "./../../../../static/reasons/key.svg"
import terms from "./../../../../static/reasons/terms.svg"
import garant from "./../../../../static/reasons/garant.svg"
import price from "./../../../../static/reasons/price.svg"
import support from "./../../../../static/reasons/support.svg"

const ReasonsBlock = (props) => {
  return (
    <div className={s.slider_wrapper}>
        <h5>Основные причины заказать памятник у нас:</h5>
        <div className={s.content}>
            <div className={s.content_block}>
                <img src={natural} alt="" />
                <span>Натуральный гранит</span>
                <p>из уникального месторождения в Карелии. Материал для изготовления памятников полностью соответствует ГОСТ и фирменным нормативам качества</p>
            </div>
            <div className={s.content_block}>
                <img src={factory} alt="" />
                <span>Заводское качество</span>
                <p>и строгий технологический контроль на всех этапах производства дают возможность создавать памятники в точном соответствии с пожеланиями заказчика и лучшими мировыми стандартами.</p>
            </div>
            <div className={s.content_block}>
                <img src={diamant} alt="" />
                <span>Алмазная шлифовка</span>
                <p>и полировка автоматических линиях ведущих отечественных и европейских производителей обеспечивает великолепный внешний вид и сохранность памятника на многие десятилетия.</p>
            </div>
            <div className={s.content_block}>
                <img src={key} alt="" />
                <span>Установка «под ключ»</span>
                <p>Наши специалисты готовы взять на себя доставку памятника к месту захоронения и установку на заводское бетонное основание по специальной технологии, используемой в капитальном строительстве.</p>
            </div>
            <div className={s.content_block}>
                <img src={terms} alt="" />
                <span>Прозрачные условия</span>
                <p>Мы работаем по договору, в котором зафиксированы ваши права и наши обязанности. Памятник будет произведен точно в срок и по заранее согласованной цене.</p>
            </div>
            <div className={s.content_block}>
                <img src={garant} alt="" />
                <span>100% гарантия</span>
                <p>Компания «ГРАНО», дает гарантию на свою продукцию сроком на 25 лет и годовую гарантию на установку. Также мы гарантируем, что вы приобретаете памятник, который полностью будет соответствовать вашему эскизу.</p>
            </div>
            <div className={s.content_block}>
                <img src={price} alt="" />
                <span>Выгодная цена</span>
                <p>Мы сами производим памятники и реализуем их без посредников по заводской цене. При необходимости мы предоставляем клиентам беспроцентную рассрочку платежа.</p>
            </div>
            <div className={s.content_block}>
                <img src={support} alt="" />
                <span>Бесплатный дизайн-проект</span>
                <p>Наши специалисты бесплатно создадут для вас 2D-модель, которая позволит выбрать наиболее подходящий вариант оформления и заранее оценить внешний вид готового памятника.</p>
            </div>
        </div>
    </div>
  );
};
export default ReasonsBlock;
