import React from "react";
import s from "./KatalogNavigation.module.scss";
import { Link } from "react-router-dom";

const KatalogNavigation = ({catalog}) => {
  return (
    <>
      <div className={s.production_wrapper}>
        <h5>Наши услуги</h5>
        <div className={s.content}>
          {
            catalog?.map((category, index) => category.visible && (
              <div key={index} className={s.content_item}>
                <Link to={`/catalog/?${index}`}>
                  <img src={category.preview} alt={category.name} />
                  <p>{category.name}</p>
                </Link>
              </div>
            ))
          }
        </div>
      </div>
    </>
  );
};
export default KatalogNavigation;
