import React, { useState } from "react";
import s from "./Header.module.scss";
import { ReactComponent as Logo } from "./../../static/Logo.svg";
import { Link } from "react-router-dom";
import cn from "classnames";

const Header = () => {
  const [openContact, setOpenContact] = useState(false);
  return (
    <header>
      <div className={cn(s.container, openContact && s.openContact)}>
        <div className={s.header_wrapper}>
          <div className={s.logo}>
            <Link to="/" title="Главная">
              <div className={s.logotype}>
                <Logo />
              </div>
              <div>
                <h1>Грано.рф</h1>
                <h2>Памятники в Твери</h2>
              </div>
            </Link>
            <button onClick={() => setOpenContact(!openContact)}>
              <span className={cn(openContact && s.openedArrow, s.arrow)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 160 88"
                >
                  <rect
                    width="113"
                    height="10"
                    x="73.013"
                    y="80.416"
                    fill="#D9D9D9"
                    rx="5"
                    transform="rotate(-45 73.013 80.416)"
                  ></rect>
                  <rect
                    width="113"
                    height="10"
                    x="7.071"
                    y="1"
                    fill="#D9D9D9"
                    rx="5"
                    transform="rotate(45 7.071 1)"
                  ></rect>
                </svg>
              </span>
              Как нас найти?
            </button>
          </div>
          <div className={s.text_block}>
            <a href="mailto:grano69@yandex.ru" title="Написать">
              grano69@yandex.ru
            </a>
            <div className={s.links}>
              <a href="tel:+79066511111" title="Позвонить">
                +7 906 651 1111
              </a>
              <a href="tel:+74822345590" title="Позвонить">
                +7 (4822) 34-55-90
              </a>
            </div>
            <div className={s.adress}>
              <p>
                г. Тверь, п. Большие
                <br /> перемерки, д. 1
              </p>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
