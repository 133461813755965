import React from "react";
import css from "./ModalForm.module.scss";
import Form from "../Form/Form";

const ModalForm = ({openModal, setOpenModal, defaulCategory}) => {
  return openModal && (
    <div className={css.modal}>
      <div className={css.mask}/>
      <div className={css.form}>
      <div className={css.close} onClick={()=> setOpenModal(false)}/>
        <Form defaulCategory={defaulCategory}/>
      </div>
    </div>
  );
};
export default ModalForm;
