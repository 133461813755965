import React from "react";
import s from "./ProductionBlock.module.scss";
import cn from "classnames";
import Image from "./../../../../static/Production.png";
import Separator from "../../../../static/Separator";
import Angel from "../../../../static/productionMask/angel.png";
import Fence from "../../../../static/productionMask/fence.png";
import Floor from "../../../../static/productionMask/floor.png";
import Heart from "../../../../static/productionMask/heart.png";
import Marbles from "../../../../static/productionMask/marbles.png";
import Plate from "../../../../static/productionMask/plate.png";
import Stand from "../../../../static/productionMask/stand.png";
import Vase from "../../../../static/productionMask/vase.png";

const ProductionBlock = () => {
  const openLink = (className, opened) => {
    opened
      ? document.getElementsByClassName(className)[0].classList.add(s.active)
      : document
          .getElementsByClassName(className)[0]
          .classList.remove(s.active);
  };
  const hovering = (className, opened) => {
    opened 
      ? document.getElementsByClassName(className)[0].classList.add(s.active)
      : document
          .getElementsByClassName(className)[0]
          .classList.remove(s.active);
  };
  return (
    <>
      <div className={s.separator}>
        <Separator />
      </div>
      <div className={s.production_wrapper}>
        <h5>Производство гранитных памятников</h5>
        <div className={s.content}>
          <div className={s.text}>
            Цель нашей компании — предложение широкого ассортимента товаров и
            услуг на постоянном высоком качестве обслуживания.
          </div>

          <div className={s.scheme_block}>
            <div className={s.mask}>
              <div className={s.Angel}>
                <img
                  src={Angel}
                  alt=""
                  onMouseOver={() => openLink(s.p1, true)}
                  onMouseOut={() => openLink(s.p1, false)}
                />
              </div>
              <div
                className={s.Fence_mask}
                onMouseOver={() => openLink(s.p3, true)}
                onMouseOut={() => openLink(s.p3, false)}
              />
              <div className={s.Fence}>
                <img
                  src={Fence}
                  alt=""
                  onMouseOver={() => openLink(s.p3, true)}
                  onMouseOut={() => openLink(s.p3, false)}
                />
              </div>
              <div className={s.Floor_mask} />
              <div className={s.Floor}>
                <img src={Floor} alt="" />
              </div>
              <div className={s.Heart}>
                <img
                  src={Heart}
                  alt=""
                  onMouseOver={() => openLink(s.p2, true)}
                  onMouseOut={() => openLink(s.p2, false)}
                />
              </div>
              <div className={s.Marbles}>
                <img
                  src={Marbles}
                  alt=""
                  onMouseOver={() => openLink(s.p4, true)}
                  onMouseOut={() => openLink(s.p4, false)}
                />
              </div>
              <div className={s.Plate}>
                <img src={Plate} alt="" />
              </div>
              <div className={s.Stand}>
                <img src={Stand} alt="" />
              </div>
              <div className={s.Vase}>
                <img
                  src={Vase}
                  alt=""
                  onMouseOver={() => openLink(s.p4, true)}
                  onMouseOut={() => openLink(s.p4, false)}
                />
              </div>
              <div className={s.description}></div>
            </div>
            <img src={Image} alt="" />
          </div>
          <div className={s.puncts_left}>
            <div
              className={cn(s.p1)}
              onMouseOver={() => hovering(s.Angel, true)}
              onMouseOut={() => hovering(s.Angel, false)}
            >
              Памятники{" "}
              <span>
                Производство на <a href="/catalog">заказ</a>
              </span>
            </div>
            <div
              className={cn(s.p2)}
              onMouseOver={() => hovering(s.Heart, true)}
              onMouseOut={() => hovering(s.Heart, false)}
            >
              Гравировка на камне{" "}
              <span>
                Производство на <a href="/catalog">заказ</a>
              </span>
            </div>
            <div
              className={cn(s.p3)}
              onMouseOver={() => hovering(s.Fence, true)}
              onMouseOut={() => hovering(s.Fence, false)}
            >
              Гранитные ограды{" "}
              <span>
                Производство на <a href="/catalog">заказ</a>
              </span>
            </div>
            <div
              className={cn(s.p4)}
              onMouseOver={() => hovering(s.Marbles, true)}
              onMouseOut={() => hovering(s.Marbles, false)}
            >
              Вазы, шары, лампатки{" "}
              <span>
                Производство на <a href="/catalog">заказ</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductionBlock;
