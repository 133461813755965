import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import s from "./KatalogPage.module.scss";
import KatalogItem from "./components/KatalogItem";
import Delivery from "../MainPage/ProductionBlock/Delivery/Delivery";
import ModalForm from "../../../constants/ModalForm/ModalForm";

const KatalogPage = ({ catalog }) => {
  const [defaulCategory, setDefaultCategory] = useState(false)
  const [productInfo, setProductInfo] = useState(
    window.location.search ? Number(window.location.search.slice(1)) : "all"
  );
  const [title, setTitle] = useState(
    Boolean(window.location.search) ?  catalog && catalog[Number(window.location.search.slice(1))]?.name : "Все товары"
  );
  useEffect(() => {
    setTitle(Boolean(window.location.search) ?  catalog && catalog[Number(window.location.search.slice(1))]?.name : "Все товары")
    setDefaultCategory(productInfo)
  }, [catalog, productInfo]);
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <ModalForm openModal={openModal} setOpenModal={setOpenModal} defaulCategory={defaulCategory} />
      <div className={s.catalog_container}>
        <h1>Каталог памятников: {title}</h1>
        <div className={s.catalog_nav}>
          <NavLink
            onClick={() => {
              setProductInfo("all");
              setTitle("Все товары");
            }}
            draggable="false"
            className={s.all}
            to={`/catalog/`}
          >
            Все товары
          </NavLink>
          {catalog?.map(
            (elem, index) =>
              elem.visible && (
                <NavLink
                  key={index}
                  draggable="false"
                  to={`/catalog/?${index}`}
                  onClick={() => {
                    setProductInfo(index);
                    setTitle(elem.name);
                  }}
                >
                  {elem.name}
                </NavLink>
              )
          )}
        </div>
        <div className={s.catalog_items}>
          {catalog?.map((el, index) =>
            el.detail
              ? (index === productInfo || productInfo === "all") &&
                el.items?.map(
                  (elem, idx) =>
                    elem.visible && (
                      <Link
                        key={idx}
                        to={`/catalog/product/?${
                          productInfo !== "all" ? productInfo : index
                        }&${idx}`}
                      >
                        <KatalogItem itemContent={elem} />
                      </Link>
                    )
                )
              : (index === productInfo || productInfo === "all") &&
                el.items?.map(
                  (elem, idx) =>
                    elem.visible && (
                      <Link key={idx} onClick={() => setOpenModal(true)}>
                        <KatalogItem itemContent={elem} />
                      </Link>
                    )
                )
          )}
        </div>
        <div className={s.delivery}>
          <Delivery />
        </div>
      </div>
    </>
  );
};

export default KatalogPage;
