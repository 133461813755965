import React, { useEffect, useState } from "react";
import s from "./VideoBlock.module.scss";
import Video from "./../../../../static/bkg_video.mp4";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useQuery } from "../../../../Hooks/useQuery";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./VideoBlockSwiper.scss";
import Image from './../../../../static/bcgVideo.png'

const VideoBlock = (props) => {
  const sales = useQuery({apiKey: 'sales'})
  const [scrollForm, setScrollForm] = useState(false);
  function scrollToForm() {
    const form = document.querySelector("#telegram-to-form");

    form.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  }
  useEffect(() => {
    scrollForm && scrollToForm(false)
  }, [scrollForm]);

  return (
    <div className={s.production_wrapper}>
      <video loop="loop" autoPlay="autoplay" muted="muted" id="bkgvideo">
        <source src={Video} type="video/mp4" />
      </video>
      <img className={s.Bcg_Mobile} src={Image} alt="" />
      <div className={s.container}>
        <div className={s.text}>
          <h3>Грано - завод по производству памятников в Твери</h3>
          <p>
            Изготовление памятников в Твери и области от производителя —
            увековечьте память близкого человека с экономией до 50%
          </p>
          <button onClick={() => setScrollForm(!scrollForm)}>
            Подобрать памятник за 1 минуту
          </button>
        </div>
        <div className={s.slider}>
          <Swiper
            slidesPerView={1}
            spaceBetween={1}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            className="video_slider"
          >
            {sales?.map((sale, index) => (
            <SwiperSlide key={index}>
              <div className={s.slide}>
                <img src={sale} alt="" />
              </div>
            </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
export default VideoBlock;
