import { useState, useEffect } from "react";
import axios from "axios";

export const useQuery = ({apiKey}) => {
    const [apitate, setApiState] = useState();
    
    useEffect(() => {
      const apiUrl = `https://xn--80aimpg.xn--80af5aeh.xn--p1ai/${apiKey}/?format=json`;
      axios.get(apiUrl).then((resp) => {
        const allPersons = resp.data;
        setApiState(allPersons);
      });
    }, [setApiState, apiKey]);

    return apitate
}