import React from "react";
import s from "./MainPage.module.scss";
import SliderBlock from "./SliderBlock/SliderBlock";
import ReasonsBlock from "./ReasonsBlock/ReasonsBlock";
import ProductionBlock from "./ProductionBlock/ProductionBlock";
import PortfolioBlock from "./PortfolioBlock/PortfoliBlock";
import Form from "../../../constants/Form/Form";
import MapBlock from "./MapBlock/MapBlock";
import KatalogNavigation from "./KatalogNavigation/KatalogNavigation";

const MainPage = ({catalog, formRef}) => {
  return (
    <div className={s.container}>
      <SliderBlock />
      <KatalogNavigation catalog={catalog}/>
      <ReasonsBlock />
      <ProductionBlock />
      <PortfolioBlock />
        <Form formRef={formRef} />
      <MapBlock />
    </div>
  );
};
export default MainPage;
