import React, { useEffect, useState } from "react";
import Select from "react-select";
import s from "./KatalogItemFilter.module.scss";

const KatalogItemFilter = ({
  productInfo,
  label,
  number,
  handleOptionClick,
  optionIndex,
  element,
}) => {
  const [selects, setSelects] = useState(productInfo.params || false);
  useEffect(() => {
    const options = [];
    productInfo?.params &&
      productInfo.params.map((el) => {
        options.push(
          el.options.map(
            (elem) => (elem = eval(`({value:"${elem}", label:"${elem}"})`))
          )
        );
        return 0;
      });
    setSelects(options);
  }, [productInfo]);

  let title = {__html: label};
  const optionValueIndex = (newValue) => element?.options.indexOf(newValue.value);

  return (
    <>
      <div className={s.filter}>
        <label htmlFor=""dangerouslySetInnerHTML={title}>
     
        </label>
        <Select
          options={selects[number]}
          placeholder={`Выберите: ${label.split('<')[0]}`}
          classNamePrefix="select"
          onChange={(newValue) =>
            handleOptionClick(
              optionIndex,
              optionValueIndex(newValue),
              label,
              newValue
            )
          }
        />
      </div>
    </>
  );
};

export default KatalogItemFilter;
