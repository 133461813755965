import React from "react";
import s from "./KatalogItem.module.scss"

const KatalogItem = ({itemContent}) => {

  return (
    <div className={s.catalog_item}>
        <div className={s.image}>
            <img src={itemContent.images[0]} alt="" />
        </div>
        <p>{itemContent?.name} от {itemContent?.price}₽</p>
    </div>
  )
};

export default KatalogItem;
