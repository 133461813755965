import React, { useEffect, useState, useRef } from "react";
import s from "./KatalogItemDetail.module.scss";
import Form from "../../../constants/Form/Form";
import KatalogItemFilter from "./components/KatalogItemFilter/KatalogItemFilter";
import Delivery from "../MainPage/ProductionBlock/Delivery/Delivery";
import Page404 from "../Page404/Page404"

const KatalogItemDetail = ({ catalog }) => {
  const [productInfo, setProductInfo] = useState(false);
  const [defaulCategory, setDefaultCategory] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [selectedOption, setSelectedOption] = useState()
  const [isPage, setIsPage] = useState(false)
  const formRef = useRef(null);
  useEffect(
    () =>
      catalog?.forEach((element, index) => {
        element.items.forEach((el, idx) => {
          if (`?${index}&${idx}` === String(window.location.search)) {
            setSelectedProduct(el.name);
            setDefaultCategory(index);
            setProductInfo(el);
            setIsPage(true)
            return;
          }
        });
        return;
      }),
    [catalog]
  );

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionClick = (optionIndex, OptionValueIndex, label, newValue) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[optionIndex] = OptionValueIndex;
    setSelectedOptions(newSelectedOptions);
    let strSelected = `${selectedOption ? selectedOption + ',' : ''} ${label}: ${newValue.value}`
    setSelectedOption(strSelected)
  };

  const getTotalPrice = () => {
    let totalPrice = productInfo?.price;
    productInfo?.params
      ? productInfo.params.forEach((option, optionIndex) => {
          const selectedOptionValue = selectedOptions[optionIndex];
          if (selectedOptionValue !== undefined) {
            const selectedOptionPrice = option.price[selectedOptionValue];
            totalPrice += parseInt(selectedOptionPrice);
          }
        })
      : (totalPrice = 0);

    return totalPrice;
  };

  return (
    <>
    
    {isPage ? 
    <div className={s.detail_container}>
      <div className={s.catalog_item}>
        <div className={s.image}>
          <p>{productInfo?.name}</p>
          <img src={productInfo?.images} alt="" />
          <p>Цена {getTotalPrice()} рублей</p>
        </div>
        <div className={s.filters}>
          {productInfo?.description && (
            <div className={s.description}>{productInfo?.description}</div>
          )}
          {productInfo?.params &&
            productInfo.params.map((element, index) => (
              <KatalogItemFilter
                key={index}
                number={index}
                productInfo={productInfo}
                label={element.name}
                handleOptionClick={handleOptionClick}
                optionIndex={index}
                element={element}
                setSelectedOption={setSelectedOption}
              />
            ))}
        </div>
        <div className={s.button}>
          <button
            onClick={() =>
              formRef.current.scrollIntoView({
                block: "center",
                behavior: "smooth",
              })
            }
          >
            Приобрести товар
          </button>
        </div>
      </div>
      <div ref={formRef}>
        <Form
          defaulCategory={defaulCategory}
          product={selectedProduct}
          optionList={selectedOption}
          price={getTotalPrice()}
        />
      </div>
      <div className={s.delivery}>
        <Delivery />
      </div>
    </div>
    : 
    <Page404 />
    }
    </>
  );
};

export default KatalogItemDetail;
