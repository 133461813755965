// libs
import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { useQuery } from "./Hooks/useQuery";
// style
import s from "./App.module.scss";
// components
import Header from "./components/Header/Header";
import VideoBlock from "./components/Pages/MainPage/VideoBlock/VideoBlock";
import MainPage from "./components/Pages/MainPage/MainPage";
import KatalogPage from "./components/Pages/KatalogPage/KatalogPage";
import KatalogItemDetail from "./components/Pages/KatalogItem/KatalogItemDetail";
import PortfolioPage from "./components/Pages/PortfolioPage/PortfolioPage";
import Page404 from "./components/Pages/Page404/Page404";
import Footer from "./components/Footer/Footer";
import ModalForm from "./constants/ModalForm/ModalForm";


const App = () => {
  const formRef = useRef(null);
  
 
  let [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    document.documentElement.scrollIntoView({block: 'start', behavior: 'smooth'})
  }, [searchParams, setSearchParams]);

  const catalogState = useQuery({apiKey: 'category'})
  const [openModal, setOpenModal] = useState(false)
  return (
    <div className={s.App}>
        <Header />
        <>
        <ModalForm openModal={openModal} setOpenModal={setOpenModal}/>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <>
                  <VideoBlock formRef={formRef} />
                  <MainPage catalog={catalogState} formRef={formRef} />
                </>
              }
            />
            <Route
              path="/catalog/"
              element={
                <>
                  <KatalogPage catalog={catalogState} />
                </>
              }
            />
              <Route
                path="/catalog/product/*"
                element={<KatalogItemDetail catalog={catalogState} />}
              />
            <Route
              exact
              path="/portfolio/"
              element={
                <>
                  <PortfolioPage />
                </>
              }
            />

            <Route exact path="*" element={<Page404 />} />
          </Routes>
        </>
        <Footer />
      </div>
  );
};

export default App;
