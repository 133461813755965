import React, { useState, useRef } from "react";
import s from "./Delivery.module.scss";
import DeliveryImage from "./../../../../../static/delivery.png";
import cn from "classnames";

const Delivery = (props) => {
  const [showDelivery, setShowDelivery] = useState();
  const showInfo = useRef(null);

  function showAndScroll() {
    setShowDelivery(true);
    showInfo.current.scrollIntoView({ block: "center", behavior: "smooth" });
  }
  return (
    <>
      <div className={s.delivery}>
        <button
          onClick={() => {
            showAndScroll();
          }}
        >
          Доставка и установка
        </button>
        <p>Условия доставки и установки на кладбища Твери и Москвы</p>
        <img src={DeliveryImage} alt="" />
      </div>
      <div
        className={cn(s.delivery_description, showDelivery && s.delivery_show)}
        ref={showInfo}
      >
        <h6 className={s.delivery_description_title}>
          Доставка и установка на кладбищах г.Тверь и г.Москва
        </h6>
        <div className={s.delivery_description_text}>
          <p>
            Установки ведутся на&nbsp;кладбищах Твери, Москвы и&nbsp;области.
            Цена начинается от&nbsp;8000&nbsp;руб.&nbsp;на&nbsp;кладбищах
            в&nbsp;Дмитро Черкассах, Лебедево, Больших Перемерках.
            К&nbsp;остальным установкам добавляется транспортный расход
            и&nbsp;сложность работы (бетонирование, армирование, укладка
            тротуарной плитки, гранитной плитки, монтаж гранитной или
            металлической ограды). Цену уточняйте у&nbsp;наших менеджеров,
            возможно изготовление любых комплексов по&nbsp;вашим экскизам,
            а&nbsp;так&nbsp;же предложим большой выбор наших проектов.
          </p>
          <p>
            Заказ памятников в&nbsp;компании &laquo;ГРАНО&raquo;&nbsp;&mdash;
            это выгодно, быстро, удобно. У&nbsp;нас на&nbsp;заводе широкий
            ассортимент надгробий и&nbsp;памятников, а&nbsp;так&nbsp;же прочих
            ритуальных изделий из&nbsp;гранита. Мы&nbsp;принимаем заказы
            на&nbsp;оптовые поставки гранитных памятников и&nbsp;аксессуаров.
            Предварительную информацию по&nbsp;вопросам поставки можно узнать
            по&nbsp;телефону. Наши специалисты проинформируют вас
            об&nbsp;условиях заказа, о&nbsp;системе оплаты и&nbsp;доставки,
            ознакомят с&nbsp;прайс-листом на&nbsp;интересующие вас модели.
            В&nbsp;каталоге продукции все памятники, надгробия и&nbsp;семейные
            памятники имеют свой собственный номер. Для удобства наших клиентов
            под фотографией каждой модели указаны размеры предлагаемых
            памятников и&nbsp;надгробий. Таким образом, выбор моделей
            вы&nbsp;можете сделать прямо у&nbsp;себя в&nbsp;офисе,
            не&nbsp;отходя от&nbsp;компьютера.
          </p>
          <p>Преимущества работы с&nbsp;нашей компанией:</p>
          <p>
            &mdash;&nbsp;Компания &laquo;ГРАНО&raquo; полностью организует
            процесс доставки по&nbsp;принципу &laquo;от&nbsp;двери
            к&nbsp;двери&raquo;.
          </p>
          <p>
            &mdash;&nbsp;Компания предлагает изделия только
            из&nbsp;высококачественного карельского гранита .
          </p>
          <p>
            &mdash;&nbsp;Мы&nbsp;поддерживаем сравнительно низкий уровень цен
            за&nbsp;счет отсутствия посредников и&nbsp;прямых поставок гранита
            от&nbsp;поставщиков из&nbsp;Карелии (Россия).
          </p>
          <p>
            Мы&nbsp;с&nbsp;удовольствием примем ваш заказ и&nbsp;будем рады
            долгосрочному сотрудничеству.
          </p>
        </div>
      </div>
    </>
  );
};
export default Delivery;
