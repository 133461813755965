import React from "react";
import s from "./SliderBlock.module.scss";
import Separator from "../../../../static/Separator";

const SliderBlock = (props) => {
  return (
    <div className={s.slider_wrapper}>
      <h5>Гранитные памятники, надгробия, оптовая продажа в Твери </h5>
      <div className="slidersuqa">
        <p>Памятники и надгробия на могилах дорогих и близких людей всегда были выражением любви и уважения к их памяти. Для родных очень важно, чтобы место захоронения имело достойный и ухоженный вид. Наша компания предлгает большой выбор готовых памятников, а также выполнение индивидуальных заказов. Для изготовления памятников используются натуральные камни — гранит и мрамор. </p>
        <p>Установки ведутся на кладбищах Твери, Москвы и области. Цена начинается от 8000 руб. на кладбищах в Дмитро Черкассах, Лебедево, Больших Перемерках. К остальным установкам добавляется транспортный расход и сложность работы (бетонирование, армирование, укладка тротуарной плитки, гранитной плитки, монтаж гранитной или металлической ограды). Цену уточняйте у наших менеджеров, возможно изготовление любых комплексов по вашим экскизам, а так же предложим большой выбор наших проектов.</p>
        <p>Заказ памятников в компании «ГРАНО» – это выгодно, быстро, удобно. У нас на заводе широкий ассортимент надгробий и памятников, а так же прочих ритуальных изделий из гранита. Мы принимаем заказы на оптовые поставки гранитных памятников и аксессуаров. Предварительную информацию по вопросам поставки можно узнать по телефону. Наши специалисты проинформируют вас об условиях заказа, о системе оплаты и доставки, ознакомят с прайс-листом на интересующие вас модели. В каталоге продукции все памятники, надгробия и семейные памятники имеют свой собственный номер. Для удобства наших клиентов под фотографией каждой модели указаны размеры предлагаемых памятников и надгробий. Таким образом, выбор моделей вы можете сделать прямо у себя в офисе, не отходя от компьютера.</p>
      </div>
      <div className={s.separator}>
        <Separator />
      </div>
    </div>
  );
};
export default SliderBlock;
