import React from "react";
import css from './Page404.module.scss'
import cn from 'classnames'
import { Link } from "react-router-dom";

const Page404 = () => (
<div className={cn("container", css.container)}>
      <div className={css.numbers}>
        <div className={css.four}>
          <svg
            viewBox="0 0 130 214"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M98.5 214V13.5033C98.5 10.4858 94.5463 9.35653 92.9526 11.9188L10.8515 143.916C9.60855 145.914 11.0456 148.5 13.399 148.5H130H12.7282"
              stroke-width="19"
            />
          </svg>
        </div>
        <div className={css.zero}>
        <svg
            viewBox="0 0 103 221"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M93 172.404C93 208.895 93 211 51.5005 211C10.0009 211 10.0009 211 10.0009 172.404V64.1347C10.0009 10 9.23243 10 51.5005 10C93 10 93 10 93 45.0873V172.404Z"
              stroke-width="19"
            />
          </svg>
        </div>
        <div className={css.four}>
        <svg
            viewBox="0 0 130 214"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M98.5 214V13.5033C98.5 10.4858 94.5463 9.35653 92.9526 11.9188L10.8515 143.916C9.60855 145.914 11.0456 148.5 13.399 148.5H130H12.7282"
              stroke-width="19"
            />
          </svg>
        </div>
      </div>
      <div className={css.text}>
        <p className={css.title}>Ошибка. Такой страницы не существует!</p>
      </div>
      <div className={css.links}>
        <Link to="/">Нажмите что бы перейти на главную страницу</Link>
      </div>
    </div>
)

export default Page404